import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ComponentModule } from '../shared/component/component.module';
import { MainRoutingModule } from './main-routing.module';
import { MainComponent } from './main.component';
import { MaintenanceComponent } from './others/maintenance/maintenance.component';
import { UserComponent } from './user/user.component';
import { UserModule } from './user/user.module';
import { AppDownloadComponent } from './others/app-download/app-download.component';


@NgModule({
  declarations: [
    MainComponent,
    MaintenanceComponent,
    AppDownloadComponent,
  ],
  imports: [
    CommonModule,
    MainRoutingModule,
    ComponentModule,
    UserModule
  ],
  exports: [
    MainComponent, 
    UserComponent,
    MaintenanceComponent
  ]
})
export class MainModule { }
