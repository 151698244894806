import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { Observable } from 'rxjs';
import { BaseResponse } from '../../models/http.model';

interface AppDownloadLink {
  iosDownloadLink: string;
  androidDownloadLink: string;
}

@Injectable({
  providedIn: 'root'
})
export class AppDownloadService {

  constructor(
    private httpService: HttpService
  ) { }

  getDownloadLinks(): Observable<BaseResponse<AppDownloadLink>> {
    return this.httpService.forClientApi().get('/system/config/download-links');
  }

}
