import { LocalizedMessagesBundle } from '../../../shared/models/i18n.model';

export const langxObj: LocalizedMessagesBundle = {
  'zh-cn': {
    'UNSUPPORTED_DEVICE_ERROR_MESSAGE': '此应用程序仅适用于 iOS 和 Android 设备。'
  },
  'en-gb': {
    'UNSUPPORTED_DEVICE_ERROR_MESSAGE': 'This app is only available for iOS and Android devices.' 
  }
}
