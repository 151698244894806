import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from '../shared/service/auth/auth.guard';
import { MainComponent } from './main.component';
import { AppDownloadComponent } from './others/app-download/app-download.component';

const routes: Routes = [
  {
    path: '', component: MainComponent,
    children: [
      { path: 'user', loadChildren: () => import('./user/user.module').then((m) => m.UserModule) },
      { path: 'home', loadChildren: () => import('./home/home.module').then((m) => m.HomeModule), 
        canActivate: [authGuard]},
      { path: 'app/download', component: AppDownloadComponent } 
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule { }
